import React from 'react';
import {
	Button,
	Col,
	FormGroup,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Properties, State } from './Properties/GalleryCreateProperties';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import { StrapLine } from '../../Partials/Fields/strap-line/StrapLineComponent';
import { Summary } from '../../Partials/Fields/summary/SummaryComponent';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Related from '../../../models/related/Related';
import MainMedia from '../../../models/mainmedia/MainMedia';
import { RelatedContentModel } from '../../Partials/Sidebar/related-content/models/related-content.model';
import { clearTempGallery, updateTempGallery } from '../../../store/action-creators/GalleryTempActionCreator';
import Gallery from '../../../models/gallery/Gallery';
import { updateGalleryTagTempRelated } from '../../../store/action-creators/RelatedTagsTempActionCreator';
import { updateGalleryMatchTempRelated } from '../../../store/action-creators/RelatedMatchTempActionCreator';
import { updateGalleryContentTempRelated } from '../../../store/action-creators/RelatedContentTempActionCreator';
import {
	clearGallerySportsTempRelated,
	updateGallerySportsTempRelated,
} from '../../../store/action-creators/RelatedSportsTempActionCreator';
import Project from '../../../models/project/Project';
import {
	GALLERY_CREATE,
	GALLERY_CREATE_FAILED,
	GALLERY_CREATE_RESOURCES_SUCCESS,
	GALLERY_CREATE_SUCCESS,
	galleryCreate,
} from '../../../store/action-creators/GalleryActionCreator';
import { isGalleryItemsTabActive } from './Helpers/GalleryHelper';
import GalleryItemsContainer from './GalleryItems/GalleryItemsContainer';
import GalleryItem from '../../../models/gallery/item/GalleryItem';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import { ContentMode, ContentTypes } from '../../../constants/content-types';
import List from '../../../models/list/List';
import SeoModel from '../../../models/seo/Seo';
import { featuresService, multiLingualService, wordCountService } from '../../../App';
import Blocky from '../../Partials/Blocky/subcomponents/blocky.component';
import SidebarCollapsibleElements from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import SidebarElementsToggle from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import {
	IMAGE_UPLOAD_FAILED,
	IMAGE_UPLOAD_REQUEST,
	IMAGE_UPLOAD_SUCCESS,
	updateEditContentTempMediaDescription,
} from '../../../store/action-creators/ImageActionCreator';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import WordCountComponent from '../../Partials/Blocky/blocks/editor/subcomponents/word-count-component';
import { toast } from 'react-toastify';
import { MandatoryFieldsService } from '../../../services/mandatory-fields-service/mandatory-fields.service';
import Flag from 'react-world-flags';
import EditorToolbar from '../../Partials/EditorToolbar/EditorToolbar';
import BlockModel from '../../Partials/Blocky/models/block.model';
import { initializeBlockyUndoRedo, resetUndoRedoBlocky } from '../../../store/action-creators/BlockyActionCreator';
import {
	createListenerForContentCreateRedirect,
	createStartingBlocky,
	createStartingEntity,
	populateExistingEntity,
} from '../../../services/content-models-service/ContentModelService';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';
import SidebarTags from '../../Partials/Sidebar/tags-refactored/tags';
import SidebarGeneralContent from '../../Partials/Sidebar/general-content/GeneralContentAttributes';
import SidebarSEO from '../../Partials/Sidebar/seo-refactored/seo.component';
import SidebarCustomData from '../../Partials/Sidebar/custom-data/custom-data.component';
import SidebarURLs from '../../Partials/Sidebar/urls-refactored/urls.component';
import { renderComponentOnlyIfFeatureEnabled } from '../../../global-helpers/global-features.helpers';
import SidebarContentFooter from '../../Partials/Sidebar/content-footer-refactored/content-footer.component';
import SidebarRelatedContent from '../../Partials/Sidebar/related-content-refactored/related-content';
import SidebarList from '../../Partials/Sidebar/list-refactored/list-wrapper';
import SidebarMainMedia from '../../Partials/Sidebar/media-refactored/media-wrapper';
import { clearSuggestedEntities } from '../../../store/action-creators/suggested-entities';

class GalleryCreate extends React.Component<Properties, State> {
	private contentValidation: MandatoryFieldsService = new MandatoryFieldsService();

	constructor(props: Properties) {
		super(props);
		this.state = {
			toggleDeleteModal: false,
			isSidebarInEdit: false,
			activeTab: new Array(4).fill('1'),
			areMandatoryFieldsFilled: true,
			totalWordCount: 0,
			totalCharCount: 0,
			addWatermarkToImageMedia: false,
			openSidebarSettingsModalFlag: false,
		};
	}

	componentWillUnmount(): void {
		this.props.resetUndoRedoBlocky();
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.initGalleryCreateSuccessListener();
		this.displayClearTempGalleryModal();
		appendBeforeUnloadResetTempSidebar(ContentTypes.GALLERY);

		this.props.gallery.id === 'temp-copy-id' && this.props.updateTempGallery(Gallery.builder(this.props.gallery).withId('temp-id').build());

		let gallery = populateExistingEntity(ContentTypes.GALLERY, this.props.gallery);
		if (gallery && (!gallery.body || gallery.body.length === 0)) {
			gallery = populateExistingEntity(ContentTypes.GALLERY, this.props.gallery, true);
		}

		this.props.updateTempGallery({ ...gallery, properties: Gallery.extractDynamicPropertiesAsObjectWithValues() });
		try {
			this.props.gallery.body && this.props.gallery.body.length > 0 && this.props.initializeBlockyUndoRedo([...this.props.gallery.body]);
		} catch {
			this.props.initializeBlockyUndoRedo(createStartingBlocky());
		}
	}

	componentDidUpdate(prevProps: Properties, prevState: State) {
		if (prevProps !== this.props && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WORDCOUNT)) {
			this.calculateTotalWordCharCount();
		}

		if (this.props.project !== prevProps.project) {
			if (multiLingualService.checkIfProjectIsMultiLingual(this.props.project.languages)) {
				const gallery = populateExistingEntity(ContentTypes.GALLERY, this.props.gallery);
				gallery && this.props.updateTempGallery(gallery);
			}
		}
	}

	calculateTotalWordCharCount() {
		let totalWordCharCount = wordCountService.calculateTotalWordCharCount(this.props.gallery.body);
		this.setState(totalWordCharCount);
	}

	// TODO Move this logic to a store variable in a given reducer ( in this case article-temp.reducer)
	displayClearTempGalleryModal() {
		if (
			(!Gallery.builder(this.props.gallery).isEmpty() ||
				(this.props.relatedContent && this.props.relatedContent.length > 0) ||
				(this.props.relatedTags && this.props.relatedTags.length > 0) ||
				(this.props.relatedMatches && Object.entries(this.props.relatedMatches).length > 0) ||
				(this.props.relatedSports && this.props.relatedSports.length > 0)) &&
			this.props.gallery.id !== 'temp-copy-id'
		) {
			this.toggleModal(true);
		}
	}

	initPageTitle() {
		document.title = this.props.t('gallery_create');
	}

	initGalleryCreateSuccessListener() {
		createListenerForContentCreateRedirect(this.props.history, GALLERY_CREATE_RESOURCES_SUCCESS, 'galleries');
	}

	toggleTab(tabPane: any, tab: any) {
		const newArray = this.state.activeTab.slice();
		newArray[tabPane] = tab;
		this.setState({
			activeTab: newArray,
		});
	}

	onTitleChange(title: string) {
		const seo = SeoModel.builder(this.props.gallery.seo).withAutoUpdateSeo(title).build();
		const gallery = Gallery.builder(this.props.gallery).withTitle(title).withSeo(seo).build();
		this.props.updateTempGallery(gallery);
	}

	onStrapLineChange(strapline: string) {
		const gallery = Gallery.builder(this.props.gallery).withStrapline(strapline).build();
		this.props.updateTempGallery(gallery);
	}

	onSummaryChange(summary: string) {
		const gallery = Gallery.builder(this.props.gallery).withSubtitle(summary).build();
		this.props.updateTempGallery(gallery);
	}

	onBodyChange = (blocks: any) => {
		const gallery = Gallery.builder(this.props.gallery).withBody(blocks).build();
		this.props.updateTempGallery(gallery);
	};

	onGalleryItemsChange(items: GalleryItem[]) {
		let gallery = Gallery.builder(this.props.gallery).withItems(items).build();
		this.props.updateTempGallery(gallery);
	}

	onWatermarkAdd(items: GalleryItem[]) {
		let newItems = items.map((item: GalleryItem) => {
			return GalleryItem.builder(item).withGeneric({ watermark: true }).build();
		});
		let gallery = Gallery.builder(this.props.gallery).withItems(newItems).build();
		this.props.updateTempGallery(gallery);
	}

	onWatermarkRemoval(items: GalleryItem[]) {
		let newItems = items.map((item: GalleryItem) => {
			return GalleryItem.builder(item).withGeneric({ watermark: false }).build();
		});
		let gallery = Gallery.builder(this.props.gallery).withItems(newItems).build();
		this.props.updateTempGallery(gallery);
	}

	toggleModal(display: boolean) {
		this.setState({
			...this.state,
			toggleDeleteModal: display,
		});
	}

	mergeRelatedFromProps() {
		let { relatedContent, relatedMatches, relatedSports, relatedTags } = this.props;
		relatedContent = relatedContent ? relatedContent : [];
		relatedMatches = relatedMatches ? relatedMatches : ([] as Related[]);
		relatedSports = relatedSports ? relatedSports : [];
		relatedTags = relatedTags ? relatedTags : [];

		return [...relatedContent, ...relatedSports, ...relatedTags, ...relatedMatches];
	}

	onSubmit() {
		const relatedList = this.mergeRelatedFromProps();
		if (this.props.gallery.mainMedia && this.props.gallery.mainMedia[0] && this.props.gallery.mainMedia[0].addDescriptionToMedia) {
			this.props.updateTempMediaDescription(this.props.gallery.mainMedia, this.props.project);
		}

		if (this.props.gallery.items && this.props.gallery.items.length > 0) {
			this.props.gallery.items.forEach((item: any) => {
				if (item.addDescriptionToMedia) {
					if (item.comment) {
						item.description = item.comment;
					}
					this.props.updateTempMediaDescription(item, this.props.project);
				}
			});
		}

		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANDATORY_FIELDS)) {
			this.contentValidation.validateByContentType(this.props.gallery, ContentTypes.GALLERY)
				? this.checkForWatermarkChecked(relatedList)
				: this.updateMandatoryFieldsFilled(false);
		} else {
			this.checkForWatermarkChecked(relatedList);
		}
	}

	checkForWatermarkChecked = (relatedList: any) => {
		if (
			//If apply watermark is checked, but no main media image is selected, saving is blocked.
			this.props.gallery.mainMedia &&
			this.props.gallery.mainMedia[0] &&
			this.props.gallery.mainMedia[0].addWatermarkToImageMedia &&
			!this.props.gallery.mainMedia[0].resourceId
		) {
			toast.error(this.props.t('missing_main_image_watermark'));
		} else {
			this.props.postGallery(this.props.gallery, JSON.parse(JSON.stringify(relatedList)), this.props.project, this.props.list);
			try {
				this.props.gallery.body && this.props.gallery.body.length > 0 && this.props.initializeBlockyUndoRedo([...this.props.gallery.body]);
			} catch {
				this.props.initializeBlockyUndoRedo(createStartingBlocky());
			}
		}
	};

	updateMandatoryFieldsFilled = (areFilledFields: boolean) => {
		this.setState({ ...this.state, areMandatoryFieldsFilled: areFilledFields });
	};

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.GALLERY);
		this.setState(newState);
	};

	onQuickWatermarkChange = (checked: boolean) => {
		let galleryGeneric = this.props.gallery.generic;
		if (galleryGeneric) {
			galleryGeneric.watermark = checked;
		} else {
			galleryGeneric = {};
			galleryGeneric.watermark = checked;
		}
		let gallery = Gallery.builder(this.props.gallery).withGeneric(galleryGeneric).build();
		this.props.updateTempGallery(gallery);
	};

	render() {
		const { t, project, gallery, types } = this.props;
		const { isSidebarInEdit, openSidebarSettingsModalFlag } = this.state;
		const langCode = multiLingualService.getProjectLanguage();

		const sidebarComponentsMap = {
			general: <SidebarGeneralContent t={t} />,
			media: (
				<SidebarMainMedia
					t={t}
					areMandatoryFieldsFilled={this.state.areMandatoryFieldsFilled}
					updateMandatoryFieldsFilled={this.updateMandatoryFieldsFilled}
				/>
			),
			content_footer: renderComponentOnlyIfFeatureEnabled(FeatureTypes.CONTENT_FOOTER, <SidebarContentFooter t={t} />),
			tags: <SidebarTags t={t} />,
			related_content: <SidebarRelatedContent t={t} />,
			urls: <SidebarURLs t={t} />,
			seo: <SidebarSEO t={t} />,
			list: <SidebarList />,
			custom_data: <SidebarCustomData t={t} />,
		};

		const saveBlockOnActions = [GALLERY_CREATE, IMAGE_UPLOAD_REQUEST];
		const saveReleaseOnActions = [GALLERY_CREATE_SUCCESS, GALLERY_CREATE_FAILED, IMAGE_UPLOAD_SUCCESS, IMAGE_UPLOAD_FAILED];

		return (
			<div id='gallery-form-wrapper' className='nav-tabs-wrapper animated fadeIn'>
				<Nav tabs className='mb-2'>
					<NavItem>
						<NavLink
							className={'bg-transparent'}
							active={this.state.activeTab[0] === '1'}
							onClick={() => {
								this.toggleTab(0, '1');
							}}
						>
							<i className='fa fa-file-text-o'></i> {t('gallery_info')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={'bg-transparent'}
							active={this.state.activeTab[0] === '2'}
							onClick={() => {
								this.toggleTab(0, '2');
							}}
						>
							<i className='fa fa-list'></i> {t('gallery_items')}
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={this.state.activeTab[0]} className={'bg-transparent border-0'}>
					<TabPane tabId='1' className={'px-0'}>
						<Row>
							<Col col='8' lg='8' md='12' sm='12' xs='12'>
								<div className='card modified-card-container'>
									<div className='card-header'>
										<div className={'d-flex align-items-center'}>
											{multiLingualService.checkIfProjectIsMultiLingual(project.languages) && (
												<div className='mr-2 d-flex'>
													<Flag
														code={gallery && gallery.language === 'en' ? 'gb' : gallery && gallery.language}
														width='20'
														fallback={<i className='fa fa-flag ml-2' />}
													/>
												</div>
											)}
											<span className={'mr-auto mb-0'}>{t('new_gallery')}</span>
											<BlockableButtonWrapper blockOnActions={saveBlockOnActions} releaseOnActions={saveReleaseOnActions}>
												<Button size='sm' color='primary' id='gallery-create-save-top' className={'ml-auto'} onClick={this.onSubmit.bind(this)}>
													<i className='fa fa-floppy-o'></i> {t('save_gallery')}
												</Button>
											</BlockableButtonWrapper>
										</div>
									</div>
									<div className='card-body'>
										<Row>
											<Col xs='12'>
												<Label htmlFor='title'>{t('title')}</Label>
												<Row>
													<Col>
														<StrapLine t={t} onChange={this.onStrapLineChange.bind(this)} value={this.props.gallery.strapline} />
													</Col>
													<Col>
														<WordCountComponent t={t} wordCount={this.state.totalWordCount} charCount={this.state.totalCharCount} />
													</Col>
												</Row>
												<Title t={t} onChange={this.onTitleChange.bind(this)} value={this.props.gallery.title} />
											</Col>
										</Row>

										<Row>
											<Col xs='12'>
												<Summary t={t} onChange={this.onSummaryChange.bind(this)} showValidation={true} value={this.props.gallery.subtitle} />
											</Col>
										</Row>

										<Row className={'mb-3'}>
											<Col xs='12'>
												<FormGroup>
													<Label htmlFor='summary'>{t('body')}</Label>
													<Blocky
														contentType={ContentTypes.GALLERY}
														t={t}
														blocks={gallery.body ? gallery.body : []}
														onChange={this.onBodyChange}
														contentLanguage={
															multiLingualService.checkIfProjectIsMultiLingual(project.languages) ? gallery.language || langCode : langCode
														}
														entityType={
															multiLingualService.checkIfProjectIsMultiLingual(project.languages) && gallery.translationGroup
																? gallery.translationGroup.contentType
																: ContentTypes.GALLERY
														}
													/>
												</FormGroup>
											</Col>
										</Row>

										<Row>
											<Col>
												<BlockableButtonWrapper blockOnActions={[GALLERY_CREATE]} releaseOnActions={[GALLERY_CREATE_SUCCESS, GALLERY_CREATE_FAILED]}>
													<Button
														color={'primary'}
														id='gallery-create-save-bottom'
														className={'text-uppercase font-weight-bold'}
														onClick={this.onSubmit.bind(this)}
													>
														<i className='fa fa-floppy-o'></i> {t('save_gallery')}
													</Button>
												</BlockableButtonWrapper>
											</Col>
											<Col>
												<WordCountComponent t={t} wordCount={this.state.totalWordCount} charCount={this.state.totalCharCount} />
											</Col>
										</Row>
									</div>
								</div>
							</Col>
							<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
								<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
								<SidebarCollapsibleElements
									isSidebarInEdit={isSidebarInEdit}
									onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.GALLERY)}
									onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
									contentType={ContentTypes.GALLERY}
									sidebarComponentsMap={sidebarComponentsMap}
									t={t}
									contentMode={ContentMode.CREATE}
								/>
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId='2' className={'px-0'}>
						<GalleryItemsContainer
							onWatermarkRemoval={this.onWatermarkRemoval.bind(this)}
							onWatermarkAdd={this.onWatermarkAdd.bind(this)}
							quickWatermarkChecked={this.props.gallery.generic && this.props.gallery.generic.watermark && this.props.gallery.generic.watermark}
							onChange={this.onGalleryItemsChange.bind(this)}
							onQuickWatermarkChange={this.onQuickWatermarkChange.bind(this)}
							t={t}
							project={project}
							items={this.props.gallery.items}
							analytics={this.props.analytics}
							types={types}
						>
							<BlockableButtonWrapper blockOnActions={saveBlockOnActions} releaseOnActions={saveReleaseOnActions}>
								<Button size='sm' color='primary' onClick={this.onSubmit.bind(this)}>
									<i className='fa fa-floppy-o'></i> {t('save_gallery')}
								</Button>
							</BlockableButtonWrapper>
						</GalleryItemsContainer>
					</TabPane>
				</TabContent>
				<Modal isOpen={this.state.toggleDeleteModal} className={'modal-info'}>
					<ModalHeader>{t('draft_detected_title')}</ModalHeader>
					<ModalBody>
						{t('draft_detected_body')}{' '}
						{multiLingualService.checkIfProjectIsMultiLingual(project.languages) && gallery.translationGroup && !gallery.translationGroup.id ? (
							<div>
								{t('draft_lang')}
								<strong>{t(gallery.language)}</strong>.
							</div>
						) : (
							''
						)}{' '}
					</ModalBody>
					<ModalFooter>
						<Button
							color='danger'
							className={'mr-auto'}
							onClick={() => {
								this.props.clearTempGallery();
								this.props.clearSuggestedEntities();

								if (this.props.project.languages.defaultLanguageCode.languageCode) {
									const gallery = createStartingEntity(ContentTypes.GALLERY);
									gallery && this.props.updateTempGallery(gallery);
									gallery && this.props.initializeBlockyUndoRedo([...gallery.body]);
								}
								this.toggleModal(false);
							}}
						>
							{t('draft_detected_delete')}
						</Button>{' '}
						<Button
							color='info'
							onClick={() => {
								this.toggleModal(false);
							}}
							className={'text-light'}
						>
							{t('draft_detected_continue')}
						</Button>
					</ModalFooter>
				</Modal>
				<EditorToolbar
					contentType={ContentTypes.GALLERY}
					onChange={this.onBodyChange}
					save={{
						invokeFunction: this.onSubmit.bind(this),
						blockOnActions: saveBlockOnActions,
						releaseOnActions: saveReleaseOnActions,
					}}
					onPreview={null}
					onCopyToProject={null}
					onAuditLog={null}
					t={t}
					hideUndoRedoButtons={isGalleryItemsTabActive(this.state.activeTab[0])}
				/>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.GALLERY, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.GALLERY, defaultTempSidebarFromApiResponse[ContentTypes.GALLERY].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		gallery: state.tempGallery.gallery,
		project: state.project.currentProject,
		profile: state.profile.profile,
		relatedContent: state.tempContentRelated.galleryRelated,
		relatedSports: state.tempSportsRelated.galleryRelated,
		relatedTags: state.tempTagsRelated.galleryRelated,
		relatedMatches: state.tempMatchRelated.galleryRelated,
		types: state.types.imageTypes,
		list: state.list.contentCreateList,
	};
}

function mapDispatchToProps(dispatch: any) {
	const isUndoRedoEnabled =
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_BLOCKY_UNDO_REDO) &&
		featuresService.checkAvailableUndoRedoPageFromFeature(ContentTypes.GALLERY);
	return {
		updateTempGallery: (gallery: Gallery) => dispatch(updateTempGallery(gallery)),
		updateTempMediaDescription: (media: MainMedia, project: Project) => dispatch(updateEditContentTempMediaDescription(media, project)),
		clearTempGallery: () => dispatch(clearTempGallery()),
		postGallery: (gallery: Gallery, related: Related[], project: Project, list: null | List) =>
			dispatch(galleryCreate(gallery, related, project, list)),
		updateTagTempRelated: (related: Related[]) => dispatch(updateGalleryTagTempRelated(related)),
		updateSportsTempRelated: (related: Related[]) => dispatch(updateGallerySportsTempRelated(related)),
		updateMatchTempRelated: (related: Related[]) => dispatch(updateGalleryMatchTempRelated(related)),
		updateRelatedContentTemp: (related: Related[]) => dispatch(updateGalleryContentTempRelated(related)),
		clearMatchTempRelated: () => dispatch(clearGallerySportsTempRelated()),
		initializeBlockyUndoRedo: (blocks: BlockModel[]) => isUndoRedoEnabled && dispatch(initializeBlockyUndoRedo(blocks)),
		resetUndoRedoBlocky: () => isUndoRedoEnabled && dispatch(resetUndoRedoBlocky()),
		clearSuggestedEntities: () => dispatch(clearSuggestedEntities()),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(GalleryCreate) as React.ComponentType;
