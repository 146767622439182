import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import ErrorAsync from '../../../../../Partials/BaseComponents/ErrorComponent/ErrorAsync';
import LiveBlogModel from '../../../models/live-blog.model';
import { slugifyString } from '../../../../../Partials/Sidebar/seo-refactored/helpers/slug.helper';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogSlug: (value: string) => void;
	allowAutoGeneratedSlug: boolean;
	setAllowAutoGeneratedSlug: (value: boolean) => void;
};

export const LiveBlogSlugSelect: FunctionComponent<Properties> = ({
	t,
	liveBlog,
	setLiveBlogSlug,
	allowAutoGeneratedSlug,
	setAllowAutoGeneratedSlug,
}) => {
	const [slugValue, setSlugValue] = useState(liveBlog.slug ? liveBlog.slug : '');

	const onSlugChange = (input: ChangeEvent<HTMLInputElement>) => {
		setSlugValue(input.target.value);
	};

	const onBlurChange = () => {
		setLiveBlogSlug(slugValue);
	};

	const onCheckboxChange = () => {
		setAllowAutoGeneratedSlug(!allowAutoGeneratedSlug);
	};

	useEffect(() => {
		if (liveBlog.title !== null && allowAutoGeneratedSlug) {
			setLiveBlogSlug(slugifyString(liveBlog.title));
		}
	}, [allowAutoGeneratedSlug]);

	useEffect(() => {
		if (liveBlog.slug) {
			setSlugValue(liveBlog.slug);
		}
	}, [liveBlog.slug]);

	return (
		<Row className='mb-3'>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_SLUG}>
					<strong>{t('live_blog_slug')}</strong>
				</Label>
				<span className='text-danger'>*</span>
				<Input
					id={'slug'}
					value={slugValue}
					className='form-control mb-2 border-dark'
					type='text'
					placeholder={allowAutoGeneratedSlug ? '' : t('slug_placeholder')}
					disabled={allowAutoGeneratedSlug}
					onChange={onSlugChange}
					onBlur={onBlurChange}
				/>
				<FormGroup check inline className={'mb-3'}>
					<Input
						className='form-check-input'
						type='checkbox'
						id='generate-slug-automatically'
						name='generate-slug-automatically'
						checked={allowAutoGeneratedSlug}
						onChange={onCheckboxChange}
					/>
					<Label className='form-check-label' check htmlFor='generate-slug-automatically'>
						{t('live_blog_slug_checkbox')}
					</Label>
				</FormGroup>
				<ErrorAsync t={t} errorType='slug_id' />
			</Col>
		</Row>
	);
};
