import React from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Properties, State } from './Properties/VideoEditProperties';
import Blocky from '../../Partials/Blocky/subcomponents/blocky.component';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import { StrapLine } from '../../Partials/Fields/strap-line/StrapLineComponent';
import { Summary } from '../../Partials/Fields/summary/SummaryComponent';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Related from '../../../models/related/Related';
import MainMedia from '../../../models/mainmedia/MainMedia';
import Project from '../../../models/project/Project';
import { allowRefresh, preventRefresh } from '../../../global/DomFunctions';
import Video from '../../../models/video/Video';
import {
	clearTempVideo,
	updateTempEditVideo,
	VIDEO_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING,
} from '../../../store/action-creators/VideoTempActionCreator';
import {
	clearVideoSportsTempRelated,
	updateVideoEditSportsTempRelated,
} from '../../../store/action-creators/RelatedSportsTempActionCreator';
import { clearVideoMatchTempRelated, updateVideoEditMatchTempRelated } from '../../../store/action-creators/RelatedMatchTempActionCreator';
import {
	clearVideoContentTempRelated,
	updateVideoEditContentTempRelated,
} from '../../../store/action-creators/RelatedContentTempActionCreator';
import { clearVideoTagTempRelated, updateVideoEditTagTempRelated } from '../../../store/action-creators/RelatedTagsTempActionCreator';
import {
	VIDEO_RECEIVED,
	VIDEO_UPDATE,
	VIDEO_UPDATE_FAILED,
	VIDEO_UPDATE_SUCCESS,
	videoRequest,
	videoUpdate,
} from '../../../store/action-creators/VideoActionCreator';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import { ContentTypes } from '../../../constants/content-types';
import { actionService, auditLogService, featuresService, multiLingualService, wordCountService } from '../../../App';
import { FeatureTypes } from '../../../services/feature-service/features.enum';
import SeoModel from '../../../models/seo/Seo';
import { Subscription } from 'rxjs';
import SidebarCollapsibleElements from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import SidebarElementsToggle from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import {
	IMAGE_MEDIA_ADD,
	IMAGE_UPLOAD_FAILED,
	IMAGE_UPLOAD_REQUEST,
	IMAGE_UPLOAD_SUCCESS,
	updateEditContentTempMediaDescription,
} from '../../../store/action-creators/ImageActionCreator';
import { initializeBlockyUndoRedo, resetAutolinking, resetUndoRedoBlocky } from '../../../store/action-creators/BlockyActionCreator';
import WordCountComponent from '../../Partials/Blocky/blocks/editor/subcomponents/word-count-component';
import { toast } from 'react-toastify';
import { MandatoryFieldsService } from '../../../services/mandatory-fields-service/mandatory-fields.service';
import { TranslationGroupsContainer } from '../../Partials/Sidebar/translation-groups/translation-groups-container.component';
import Flag from 'react-world-flags';
import { AuditLogContainer, DISPLAY_AUDIT } from '../audit-log/audit-log.container';
import OneSignalModal from '../../Partials/Modals/OneSignal/OneSignalModal';
import EditorToolbar from '../../Partials/EditorToolbar/EditorToolbar';
import BlockModel from '../../Partials/Blocky/models/block.model';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';
import SidebarTags from '../../Partials/Sidebar/tags-refactored/tags';
import SidebarGeneralContent from '../../Partials/Sidebar/general-content/GeneralContentAttributes';
import SidebarSEO from '../../Partials/Sidebar/seo-refactored/seo.component';
import SidebarCustomData from '../../Partials/Sidebar/custom-data/custom-data.component';
import SidebarURLs from '../../Partials/Sidebar/urls-refactored/urls.component';
import { renderComponentOnlyIfFeatureEnabled } from '../../../global-helpers/global-features.helpers';
import SidebarContentFooter from '../../Partials/Sidebar/content-footer-refactored/content-footer.component';
import SidebarRelatedContent from '../../Partials/Sidebar/related-content-refactored/related-content';
import SidebarList from '../../Partials/Sidebar/list-refactored/list-wrapper';
import SidebarMainMedia from '../../Partials/Sidebar/media-refactored/media-wrapper';
class VideoEdit extends React.Component<Properties, State> {
	private actionServiceSubscription = new Subscription();
	private contentValidation: MandatoryFieldsService = new MandatoryFieldsService();

	constructor(props: any) {
		super(props);
		this.state = {
			isContentLoading: true,
			isSidebarInEdit: false,
			areMandatoryFieldsFilled: true,
			totalWordCount: 0,
			totalCharCount: 0,
			addWatermarkToImageMedia: this.props.video.generic && this.props.video.generic.mainMediaWatermark,
			openSidebarSettingsModalFlag: false,
		};
		this.registerActionEventListener();
	}

	componentWillUnmount() {
		this.props.relatedSports && this.props.clearSportsTempRelated();
		this.props.relatedTags && this.props.clearTagTempRelated();
		this.props.relatedMatches && this.props.clearMatchTempRelated();
		this.props.relatedContent && this.props.clearContentTempRelated();
		this.actionServiceSubscription.unsubscribe();
		this.props.resetUndoRedoBlocky();
		allowRefresh();
	}

	componentDidUpdate(prevProps: Properties, prevState: State) {
		if (this.props.video.body !== prevProps.video.body) {
			this.props.updateSportsTempRelated(this.props.relatedSports);
			this.props.updateMatchTempRelated(this.props.relatedMatches);
		}

		if (this.props.relatedMatches !== prevProps.relatedMatches) {
			this.props.updateMatchTempRelated(this.props.relatedMatches);
		}

		if (prevProps !== this.props && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WORDCOUNT)) {
			this.calculateTotalWordCharCount();
		}
		if (this.props.match.params.id !== prevProps.match.params.id) {
			window.scrollTo(0, 0);
			this.toggleContentLoadingState(true);
			this.props.resetAutolinking();
			this.props.getVideo(this.props.match.params.id, this.props.project);
		}
		if (this.props.video.generic != prevProps.video.generic) {
			this.setState({ addWatermarkToImageMedia: this.props.video.generic && this.props.video.generic.mainMediaWatermark });
		}
	}

	calculateTotalWordCharCount() {
		let totalWordCharCount = wordCountService.calculateTotalWordCharCount(this.props.video.body);
		this.setState(totalWordCharCount);
	}

	registerActionEventListener() {
		this.actionServiceSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === VIDEO_RECEIVED) {
				setTimeout(() => {
					this.toggleContentLoadingState(false);
				}, 800);
			}

			if (action === VIDEO_TEMP_EDIT_UPDATE_BEFORE_REDIRECTING) {
				this.onSubmit();
			}
		});
	}

	toggleContentLoadingState(isLoading: boolean) {
		this.setState({
			...this.state,
			isContentLoading: isLoading,
		});
	}

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.VIDEO);
		this.setState(newState);
	};

	componentDidMount(): void {
		this.props.updateMatchTempRelated([]);
		this.props.updateSportsTempRelated([]);
		this.setState({ addWatermarkToImageMedia: this.props.video.generic && this.props.video.generic.mainMediaWatermark });
		this.props.resetAutolinking();
		this.initPageTitle();
		this.props.getVideo(this.props.match.params.id, this.props.project);
		preventRefresh();
		appendBeforeUnloadResetTempSidebar(ContentTypes.VIDEO);
		try {
			this.props.video.body && this.props.video.body.length > 0 && this.props.initializeBlockyUndoRedo([...this.props.video.body]);
		} catch {
			this.props.initializeBlockyUndoRedo([]);
		}

		// Controls for when the user tries to directly access a video if the video feature is disabled
		if (!featuresService.areFeaturesEnabled([FeatureTypes.VIDEO])) {
			// @ts-ignore
			this.props.history.push('/dashboard');
		}
	}

	initPageTitle() {
		document.title = this.props.t('video_edit');
	}

	onTitleChange(title: string) {
		const seo = SeoModel.builder(this.props.video.seo).withAutoUpdateSeo(title).build();
		const video = Video.builder(this.props.video).withTitle(title).withSeo(seo).build();
		this.props.updateTempVideo(video);
	}

	onStrapLineChange(strapline: string) {
		const video = Video.builder(this.props.video).withStrapline(strapline).build();
		this.props.updateTempVideo(video);
	}

	onSummaryChange(summary: string) {
		const video = Video.builder(this.props.video).withSubtitle(summary).build();
		this.props.updateTempVideo(video);
	}

	onSubmit() {
		const relatedList = this.mergeRelatedFromProps();
		if (this.props.video.mainMedia && this.props.video.mainMedia[0] && this.props.video.mainMedia[0].addDescriptionToMedia) {
			this.props.updateTempMediaDescription(this.props.video.mainMedia, this.props.project);
		}

		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANDATORY_FIELDS)) {
			this.contentValidation.validateByContentType(this.props.video, ContentTypes.VIDEO)
				? this.checkForWatermarkChecked(relatedList)
				: this.updateMandatoryFieldsFilled(false);
		} else {
			this.checkForWatermarkChecked(relatedList);
		}
	}

	checkForWatermarkChecked = (relatedList: any) => {
		if (
			//If apply watermark is checked, but no main media image is selected, saving is blocked.
			this.props.video.mainMedia &&
			this.props.video.mainMedia[0] &&
			this.props.video.mainMedia[0].addWatermarkToImageMedia &&
			!this.props.video.mainMedia[0].resourceId
		) {
			toast.error(this.props.t('missing_main_image_watermark'));
		} else {
			this.props.updateVideo(this.props.video, JSON.parse(JSON.stringify(relatedList)), this.props.project);
			try {
				this.props.video.body && this.props.video.body.length > 0 && this.props.initializeBlockyUndoRedo([...this.props.video.body]);
			} catch {
				this.props.initializeBlockyUndoRedo([]);
			}
		}
	};

	updateMandatoryFieldsFilled = (areFilledFields: boolean) => {
		this.setState({ ...this.state, areMandatoryFieldsFilled: areFilledFields });
	};

	mergeRelatedFromProps() {
		const { relatedContent, relatedMatches, relatedSports, relatedTags } = this.props;

		return [...relatedContent, ...relatedSports, ...relatedTags, ...relatedMatches];
	}

	onBodyChange = (blocks: any) => {
		const video = Video.builder(this.props.video).withBody(blocks).build();
		this.props.updateTempVideo(video);
	};

	displayAuditLog = () => {
		actionService.emitUiAction({
			type: DISPLAY_AUDIT,
			data: {
				profileId: this.props.profile.id,
				contentId: this.props.video.id,
				contentTitle: this.props.video.title,
				contentType: ContentTypes.VIDEO,
			},
		});
	};

	render() {
		const { t, video, project } = this.props;
		const { isSidebarInEdit, openSidebarSettingsModalFlag } = this.state;

		const sidebarComponentsMap = {
			general: <SidebarGeneralContent t={t} />,
			translations: (
				<TranslationGroupsContainer
					t={t}
					currentContentTranslations={video.translationGroup}
					contentType={'video'}
					content={video}
					analytics={this.props.analytics}
				/>
			),
			media: (
				<SidebarMainMedia
					t={t}
					areMandatoryFieldsFilled={this.state.areMandatoryFieldsFilled}
					updateMandatoryFieldsFilled={this.updateMandatoryFieldsFilled}
				/>
			),
			content_footer: renderComponentOnlyIfFeatureEnabled(FeatureTypes.CONTENT_FOOTER, <SidebarContentFooter t={t} />),
			tags: <SidebarTags t={t} />,
			related_content: <SidebarRelatedContent t={t} />,
			urls: <SidebarURLs t={t} />,
			seo: <SidebarSEO t={t} />,
			list: <SidebarList />,
			custom_data: <SidebarCustomData t={t} />,
		};

		const saveBlockOnActions = [VIDEO_UPDATE, IMAGE_UPLOAD_REQUEST];
		const saveReleaseOnActions = [VIDEO_UPDATE_SUCCESS, VIDEO_UPDATE_FAILED, IMAGE_UPLOAD_SUCCESS, IMAGE_UPLOAD_FAILED, IMAGE_MEDIA_ADD];

		return (
			<div className={`animated fadeIn ${this.props.reduxLoadingState && 'loading-overlay'}`}>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						<div className='card modified-card-container'>
							<div className='card-header'>
								<div className={'row d-flex align-items-center'}>
									{multiLingualService.checkIfProjectIsMultiLingual(project.languages) && Object.entries(video).length > 0 && video.language && (
										<div className='col mr-2 text-truncate d-flex'>
											<Flag code={video.language === 'en' ? 'gb' : video.language} width='20' fallback={<i className='fa fa-flag ml-2' />} />
											<label className={'mb-0 text-truncate ml-2'}>
												{t('video_edit')}: {video.title}
											</label>
										</div>
									)}
									<div className='py-1 col d-flex'>
										<BlockableButtonWrapper blockOnActions={saveBlockOnActions} releaseOnActions={saveReleaseOnActions}>
											<Button size='sm' color='primary' id='video-edit-save-top' className={'ml-auto text-nowrap'} onClick={this.onSubmit.bind(this)}>
												<i className='fa fa-floppy-o' /> {t('save_video')}
											</Button>
										</BlockableButtonWrapper>
										{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.ONESIGNAL_INTEGRATION) && (
											<OneSignalModal contentType={ContentTypes.VIDEO} t={t} content={video} />
										)}
										{auditLogService.isFeatureEnabled(featuresService, this.props.profile.id) && (
											<Button size='sm' color='secondary' className='ml-2 text-nowrap' onClick={this.displayAuditLog}>
												<i className={'fa fa-clock-rotate-left text-white'} />
											</Button>
										)}
									</div>
								</div>
							</div>
							<AuditLogContainer t={this.props.t} />
							<div className='card-body'>
								<div>
									<Row>
										<Col xs='12'>
											<Label htmlFor='title'>{t('title')}</Label>
											<Row>
												<Col>
													<StrapLine t={t} onChange={this.onStrapLineChange.bind(this)} value={video ? video.strapline : ''} />
												</Col>
												<Col>
													<WordCountComponent t={t} wordCount={this.state.totalWordCount} charCount={this.state.totalCharCount} />
												</Col>
											</Row>
											<Title t={t} onChange={this.onTitleChange.bind(this)} value={video ? video.title : ''} />
										</Col>
									</Row>
									<Row>
										<Col xs='12'>
											<Summary t={t} onChange={this.onSummaryChange.bind(this)} showValidation={true} value={video ? video.subtitle : ''} />
										</Col>
									</Row>
									<Row className={'mb-3'}>
										<Col xs='12'>
											<FormGroup>
												<Label htmlFor='summary'>{t('body')}</Label>
												<Blocky
													contentType={ContentTypes.VIDEO}
													t={t}
													blocks={video.body ? video.body : []}
													onChange={this.onBodyChange}
													contentLanguage={video.language}
													entityType={
														multiLingualService.checkIfProjectIsMultiLingual(project.languages) &&
														video.translationGroup &&
														video.translationGroup.contentType
													}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col>
											<BlockableButtonWrapper blockOnActions={saveBlockOnActions} releaseOnActions={saveReleaseOnActions}>
												<Button
													color={'primary'}
													id='video-edit-save-bottom'
													className={'text-uppercase font-weight-bold'}
													onClick={this.onSubmit.bind(this)}
												>
													<i className='fa fa-floppy-o'></i> {t('save_video')}
												</Button>
											</BlockableButtonWrapper>
										</Col>
										<Col>
											<WordCountComponent t={t} wordCount={this.state.totalWordCount} charCount={this.state.totalCharCount} />
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</Col>
					{!this.state.isContentLoading && (
						<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
							<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
							<SidebarCollapsibleElements
								isSidebarInEdit={isSidebarInEdit}
								onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.VIDEO)}
								onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
								contentType={ContentTypes.VIDEO}
								sidebarComponentsMap={sidebarComponentsMap}
								t={t}
								currentProject={project}
							/>
						</Col>
					)}
				</Row>
				<EditorToolbar
					contentType={ContentTypes.VIDEO}
					onChange={this.onBodyChange}
					save={{
						invokeFunction: this.onSubmit.bind(this),
						blockOnActions: saveBlockOnActions,
						releaseOnActions: saveReleaseOnActions,
						blockUntilCorrectContentIsLoaded: !video.id,
					}}
					onPreview={null}
					onCopyToProject={null}
					onAuditLog={this.displayAuditLog}
					t={t}
					content={this.props.video}
					showNotifyUsers
				/>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.VIDEO, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.VIDEO, defaultTempSidebarFromApiResponse[ContentTypes.VIDEO].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		video: state.tempVideo.videoEdit,
		project: state.project.currentProject,
		profile: state.profile.profile,
		relatedContent: state.tempContentRelated.videoEditRelated,
		relatedSports: state.tempSportsRelated.videoEditRelated,
		relatedTags: state.tempTagsRelated.videoEditRelated,
		relatedMatches: state.tempMatchRelated.videoEditRelated,
		reduxLoadingState: state.ui.contentLoadingState[ContentTypes.VIDEO],
	};
}

function mapDispatchToProps(dispatch: any) {
	const isUndoRedoEnabled =
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_BLOCKY_UNDO_REDO) &&
		featuresService.checkAvailableUndoRedoPageFromFeature(ContentTypes.VIDEO);
	return {
		updateVideo: (video: Video, related: Related[], project: Project) => dispatch(videoUpdate(video, related, project)),
		updateTempMediaDescription: (media: MainMedia, project: Project) => dispatch(updateEditContentTempMediaDescription(media, project)),
		updateTempVideo: (video: Video) => dispatch(updateTempEditVideo(video)),
		clearTempVideo: () => dispatch(clearTempVideo()),
		updateTagTempRelated: (related: Related[]) => dispatch(updateVideoEditTagTempRelated(related)),
		updateSportsTempRelated: (related: Related[]) => dispatch(updateVideoEditSportsTempRelated(related)),
		updateMatchTempRelated: (related: Related[]) => dispatch(updateVideoEditMatchTempRelated(related)),
		updateRelatedContentTemp: (related: Related[]) => dispatch(updateVideoEditContentTempRelated(related)),
		getVideo: (id: string, project: Project) => dispatch(videoRequest(id, project)),
		clearMatchTempRelated: () => dispatch(clearVideoMatchTempRelated()),
		clearTagTempRelated: () => dispatch(clearVideoTagTempRelated()),
		clearContentTempRelated: () => dispatch(clearVideoContentTempRelated()),
		clearSportsTempRelated: () => dispatch(clearVideoSportsTempRelated()),
		resetAutolinking: () => dispatch(resetAutolinking()),
		initializeBlockyUndoRedo: (blocks: BlockModel[]) => isUndoRedoEnabled && dispatch(initializeBlockyUndoRedo(blocks)),
		resetUndoRedoBlocky: () => isUndoRedoEnabled && dispatch(resetUndoRedoBlocky()),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps), withRouter)(VideoEdit) as React.ComponentType;
