export enum ContentTypes {
	article = 'article',
	video = 'video',
	poll = 'poll',
	instatTeamComparison = 'instatTeamComparison',
	instatPlayerComparison = 'instatPlayerComparison',
	instatPlayerSeasonPerformance = 'instatPlayerSeasonPerformance',
	instatTeamSeasonPerformance = 'instatTeamSeasonPerformance',
	instatPostMatchTeamsStats = 'instatPostMatchTeamsStats',
	instatPostMatchPlayersStats = 'instatPostMatchPlayersStats',
	gallery = 'gallery',
	image = 'image',
	embed = 'embed',
	link = 'link',
	highlight = 'highlight',
	createGallery = 'createGallery',
	banner = 'banner',
	dugoutVideo = 'dugoutVideo',
	onnetworkVideo = 'onnetworkVideo',
	spotlightAudio = 'spotlightAudio',
	gbVision = 'gbVision',
	audioBlock = 'audioBlock',
	imago = 'imago',
	team = 'team',
	player = 'player',
	coach = 'coach',
	youtubeSports = 'youtubeSports',
	customBlock = 'custom_block',
	amgBlock = 'amgBlock',
	getty = 'getty',
}

export enum EditorTypes {
	editor = 'editor_block',
	paragraph = 'paragraph',
	quote = 'quote',
	heading = 'heading',
	table = 'table',
	list = 'list',
}

export enum WidgetTypes {
	widget = 'widget_smp',
	odds = 'widgetOdds',
	compareOdds = 'compareOdds',
	playerProfile = 'playerProfile',
	teamProfile = 'teamProfile',
	standings = 'standings',
	polls = 'widgetPolls',
	match = 'widgetMatch',
	fixtures = 'widgetFixtures',
	topScorers = 'widgetTopScorers',
	teamPlayers = 'teamPlayers',
	widgetPlayerH2H = 'widgetPlayerH2H',
	widgetTeamComparison = 'widgetTeamComparison',
	widgetMatchListStageRound = 'widgetMatchListStageRound',
	widgetTeamForm = 'widgetTeamForm',
	widgetMostDecoratedPlayers = 'widgetMostDecoratedPlayers',
	widgetPlayerCareer = 'widgetPlayerCareer',
	widgetSquadStatistics = 'widgetSquadStatistics',
	instatTeamComparison = 'instatTeamComparison',
}

export enum WidgetsIds {
	widgetOdds = 'odds-listing',
	widgetMatch = 'widget-match',
	teamProfile = 'team',
	teamPlayers = 'widget-team-players',
	playerProfile = 'player',
	widgetPlayerH2H = 'player-h2h',
	widgetTopScorers = 'top-scorers',
	widgetTeamComparison = 'team-comparison',
	widgetFixtures = 'fixtures-widget',
	standings = 'standings',
	widgetMatchListStageRound = 'match-list',
	widgetTeamForm = 'team-form',
	widgetMostDecoratedPlayers = 'widget-most-decorated-players',
	widgetPlayerCareer = 'widget-player-career',
	widgetSquadStatistics = 'widget-squad-statistics',
}

export enum FansUnitedWidgetsTypes {
	widget = 'widget_fu',
	widget1x2 = 'widget1x2',
	doubleChance = 'widgetDoubleChance',
	correctScore = 'widgetCorrectScore',
	htFt = 'widgetHtFt',
	teamPredictions = 'widgetTeamPredictions',
	playerPredictions = 'widgetPlayerPredictions',
	topX = 'widgetTopX',
	matchQuiz = 'widgetMatchQuiz',
	leaderboard = 'widgetLeaderboard',
	classicQuiz = 'widgetClassicQuiz',
	eitherOr = 'widgetEitherOr',
}

export enum FansUnitedWidgetsIds {
	widget1x2 = 'widget-1x2',
	widgetDoubleChance = 'widget-double-chance',
	widgetCorrectScore = 'widget-correct-score',
	widgetHtFt = 'widget-ht-ft',
	widgetTeamPredictions = 'widget-yes-no',
	widgetPlayerPredictions = 'widget-yes-no',
}

export enum WidgetTypesV2 {
	widget = 'widget_smp_V2',
	footballSingleEvent = 'widgetFootballSingleEvent',
	footballPlayer = 'widgetFootballPlayer',
	footballKnockout = 'widgetFootballKnockout',
	footballStandings = 'widgetFootballStandings',
	footballLivescore = 'widgetFootballLivescore',
	footballTeamProgramme = 'widgetFootballTeamProgramme',
	footballTeam = 'widgetFootballTeam',
	footballTeamSquad = 'widgetFootballTeamSquad',
	footballTournamentProgramme = 'widgetFootballTournamentProgramme',
	footballPlayerH2H = 'widgetFootballPlayerH2H',
	footballTeamH2H = 'widgetFootballTeamH2H',
	footballTeamH2HMatch = 'widgetFootballTeamH2HMatch',
	footballMatchesH2H = 'widgetFootballMatchesH2H',
	footballTeamSeasonStats = 'widgetFootballTeamSeasonStats',
	footballTeamForm = 'widgetFootballTeamForm',
	footballTopScorers = 'widgetFootballTopScorers',
	footballMostDecoratedPlayers = 'widgetFootballMostDecoratedPlayers',
	footballLineups = 'widgetFootballLineups',
	footballOdds = 'widgetFootballOdds',
	footballStakes = 'widgetFootballStakes',
	basketballSingleEvent = 'widgetBasketballSingleEvent',
	basketballLivescore = 'widgetBasketballLivescore',
	basketballStandings = 'widgetBasketballStandings',
	basketballTournamentProgramme = 'widgetBasketballTournamentProgramme',
	basketballTeamProgramme = 'widgetBasketballTeamProgramme',
	tennisSingleEvent = 'widgetTennisSingleEvent',
	tennisLivescore = 'widgetTennisLivescore',
	tennisRanking = 'widgetTennisRanking',
	tennisPlayoff = 'widgetTennisPlayoff',
	tennisAthleteProgramme = 'widgetTennisAthleteProgramme',
	tennisTournamentProgramme = 'widgetTennisTournamentProgramme',
}

export enum WidgetsV2Ids {
	widgetFootballSingleEvent = 'football-single-event',
	widgetFootballPlayer = 'player',
	widgetFootballKnockout = 'knockout',
	widgetFootballStandings = 'standings',
	widgetFootballLivescore = 'livescore',
	widgetFootballTeamProgramme = 'team-programme',
	widgetFootballTeam = 'team',
	widgetFootballTeamSquad = 'team-squad',
	widgetFootballTournamentProgramme = 'tournament-programme',
	widgetFootballPlayerH2H = 'player-h2h',
	widgetFootballTeamH2H = 'team-h2h',
	widgetFootballTeamH2HMatch = 'team-h2h-match',
	widgetFootballMatchesH2H = 'matches-h2h',
	widgetFootballTeamForm = 'team-form',
	widgetFootballTopScorers = 'top-scorers',
	widgetFootballMostDecoratedPlayers = 'most-decorated',
	widgetFootballOdds = 'odds',
	widgetFootballStakes = 'stakes',
	widgetFootballLineups = 'lineups',
	widgetBasketballSingleEvent = 'basketball-single-event',
	widgetBasketballLivescore = 'basketball-livescore',
	widgetBasketballStandings = 'basketball-standings',
	widgetBasketballTournamentProgramme = 'basketball-tournament-programme',
	widgetBasketballTeamProgramme = 'basketball-team-programme',
	widgetTennisSingleEvent = 'tennis-single-event',
	widgetTennisLivescore = 'tennis-livescore',
	widgetTennisRanking = 'tennis-ranking',
	widgetTennisPlayoff = 'tennis-playoff',
	widgetTennisAthleteProgramme = 'tennis-athlete-programme',
	widgetTennisTournamentProgramme = 'tennis-tournament-programme',
}

export enum BugWidget {
	arrayBug = 'arrayBug',
}
