export enum SPORT_SELECT_ATTRIBUTES {
	TEAM_SELECT = 'widget-team-select',
	PLAYER_SELECT = 'widget-player-select',
	TOURNAMENT_SELECT = 'widget-tournament-select',
	COMPETITION_SELECT = 'widget-competition-select',
	SEASON_SELECT = 'widget-season-select',
	STAGE_SELECT = 'widget-stage-select',
	GROUP_SELECT = 'widget-group-select',
	ROUND_SELECT = 'widget-round-select',
	DISPLAY_ODDS_SELECT = 'widget-display-odds-select',
	EVENT_SELECT = 'widget-event-select',
	SPORTS_ENTITY = 'widget-sports-entity-select',
	DATE_SELECT = 'widget-date-select',
	BOOKMAKER_SELECT = 'widget-bookmaker-select',
	MARKET_SELECT = 'widget-market-select',
	MARKET_VALUE_TYPE_SELECT = 'widget-market-value-type-select',
	FANS_UNITED_ENABLED = 'widget-fans-united-enabled-select',
	FANS_UNITED_EXPANDED = 'widget-fans-united-expanded-select',
	SORT_DIRECTION_SELECT = 'widget-sort-direction-select',
	SORT_DIRECTION_FIXTURES_SELECT = 'widget-sort-direction-fixtures-select',
	SORT_DIRECTION_RESULTS_SELECT = 'widget-sort-direction-results-select',
	PLAYER_SEASON_SELECT = 'widget-player-season-select',
	TEAM_SEASON_SELECT = 'widget-team-season-select',
	MATCH_BY_STAGE_SELECT = 'widget-match-by-stage-or-season-select',
	GENERIC_SELECT = 'widget-generic-select',
	TOP_SCORERS_TEAM_SELECT = 'widget-top-scorers-team-select',
	TOP_SCORERS_SELECT = 'widget-top-scorers-select',
	MOST_DECORATED_PLAYERS_SELECT = 'widget-most-decorated-players-select',
	MATCH_TYPE = 'widget-match-type-select',
	DEFAULT_TAB = 'widget-default-tab-select',
	HOME_TEAM_COLOR = ' widget-home-team-color',
	AWAY_TEAM_COLOR = ' widget-away-team-color',
	HIDE_EVENTS = ' widget-events',
	OFFSET = ' widget-offset',
	ELEMENTS = 'widget-elements-select',
	STANDING_TYPE_SELECT = 'widget-standing-type-select',
	HIGHLIGHT_PLAYERS_SELECT = 'widget-highlight-players-select',
	LINEUPS_ELEMENTS = 'widget-lineups-elements',
	MATCH = 'widget-match-select',
	FIXED_AMOUNT = 'widget-fixed-amount-select',
	AMOUNT = 'widget-amount-select',
	GENDER = 'widget-gender-select',
}

export enum WIDGET_ATTRIBUTE_NAMES {
	COMPETITION = ' data-competition=',
	SEASON = ' data-season=',
	STAGE = ' data-stage=',
	ROUND = ' data-round=',
	ROUNDS = ' data-rounds=',
	GROUPS = ' data-groups=',
	SORT_DIRECTION = ' data-sort-direction=',
	SORT_DIRECTION_FIXTURES = ' data-sort-direction-fixtures=',
	SORT_DIRECTION_RESULTS = ' data-sort-direction-results=',
	TEAM = ' data-team=',
	PLAYER = ' data-player=',
	MATCH = ' data-match-id=',
	BETTING_ID = ' data-odds-betting-id=',
	MAIN_ODD_PROVIDER_ID = ' data-odds-main-betting-provider-id=',
	MARKET = ' data-odds-market=',
	ODDS_DISPLAY = ' data-odds-display=',
	ODDS_MARKET_VALUE_TYPE = ' data-odds-market-value-type=',
	DATE = ' data-date=',
	DISPLAY_TABS = ' data-display-tabs=',
	TABS = ' data-tabs=',
	DEFAULT_TAB = ' data-default-tab=',
	HEADER_DEFAULT_OPTION = ' data-header-default-option=',
	HEADER_DISPLAY = ' data-header-display=',
	LIMIT = ' data-limit=',
	TITLE = ' data-title=',
	FILTER = ' data-filter=',
	TOP_SCORERS = ' data-top-scorers=',
	HIGHLIGHT_PLAYERS = ' data-highlight-entities=',
	SELECTIONS = ' data-selections=',
	ID_SCHEMA = ' fans-united-id-schema=',
	FANS_UNITED_ENABLED = ' fans-united-enabled=',
	FANS_UNITED_EXPANDED = ' fans-united-expanded=',
	API_KEY = ' fans-united-api-key=',
	ENVIRONMENT = ' fans-united-environment=',
	CLIENT_ID = ' fans-united-client-id=',
	MATCH_TYPE = ' data-match-type=',
	HOME_TEAM_COLOR = ' data-home-team-color=',
	AWAY_TEAM_COLOR = ' data-away-team-color=',
	HIDE_EVENTS = ' data-events=',
	DISPLAY_MATCH_HEADER = ' data-display-match-header=',
	START_POSITION = ' data-start-position=',
	ELEMENTS = ' data-elements=',
	SPORT_ENTITY_ONE = ' data-sport-entity-one=',
	SPORT_ENTITY_TWO = ' data-sport-entity-two=',
	TEAM_IDS = ' data-team-ids=',
	ROUNDS_FILTER = ' data-rounds-filter=',
	STANDING_TYPE = ' data-standing-type-subcategory=',
	OFFSET = ' data-offset=',
	TOURNAMENT = ' data-tournament=',
	REFRESH_TIME = ' data-refresh-time=',
	AMOUNT = ' data-amount=',
	FIXED = ' data-fixed=',
	GAME_TYPE = ' data-game-type=',
	GENDER = ' data-gender=',
	HIGHLIGHT_ENTITIES = 'data-highlight-entities=',
}

export enum WIDGET_PREVIEW_ATTRIBUTE_NAMES {
	COMPETITION = 'dataCompetition:',
	SEASON = 'dataSeason:',
	STAGE = 'dataStage:',
	ROUND = 'dataRounds:',
	SORT_DIRECTION = 'dataSortDirection:',
	SORT_DIRECTION_FIXTURES = 'dataSortDirectionFixtures:',
	SORT_DIRECTION_RESULTS = 'dataSortDirectionResults:',
	TEAM = 'dataTeam:',
	PLAYER = 'dataPlayer:',
	MATCH = 'dataMatchId:',
	BETTING_ID = 'dataOddsBettingId:',
	MAIN_ODDS_BETTING_PROVIDER_ID = 'dataOddsMainOddsProviderId:',
	MARKET = 'dataOddsMarket:',
	ODDS_DISPLAY = 'dataOddsDisplay:',
	DATE = 'dataDate.date:',
	DISPLAY_TABS = 'dataDisplayTabs:',
	TABS = 'dataTabs.',
	DEFAULT_TAB = 'dataDefaultTab:',
	HEADER_DEFAULT_OPTION = 'dataHeaderDefaultOption:',
	FANS_UNITED_ENABLED = 'fansUnitedEnabled:',
	FANS_UNITED_EXPANDED = 'fansUnitedExpanded:',
	COMPETITION_LIST = 'sdkOptions.dataConfigCompetitionList:',
	LANGUAGE = 'sdkOptions.dataConfigLang:',
	PROJECT = 'sdkOptions.dataConfigProject:',
	ODD_CLIENT = 'sdkOptions.dataConfigOddClient:',
	MATCH_TYPE = 'dataMatchType:',
	HOME_TEAM_COLOR = 'dataHomeTeamColor:',
	AWAY_TEAM_COLOR = 'dataAwayTeamColor:',
	HIDE_EVENTS = 'dataHideEvents:',
	DISPLAY_MATCH_HEADER = 'dataDisplayMatchHeader:',
	LIMIT = 'dataLimit:',
	OFFSET = 'dataOffset:',
	START_POSITION = 'dataStartPosition:',
	ELEMENTS = 'dataElements:',
	SPORT_ENTITY_ONE_ID = 'dataSportEntityOne.id:',
	SPORT_ENTITY_TWO_ID = 'dataSportEntityTwo.id:',
	SPORT_ENTITY_ONE_SEASON_ID = 'dataSportEntityOne.seasonId:',
	SPORT_ENTITY_TWO_SEASON_ID = 'dataSportEntityTwo.seasonId:',
	INFO_PARAMETERS = 'dataElements.info',
	STATS_PARAMETERS = 'dataElements.stats',
	SPORT_ENTITY_ONE = 'dataSportEntityOne:',
	SPORT_ENTITY_TWO = 'dataSportEntityTwo:',
	ROUNDS_FILTER = 'dataRoundsFilter',
	STANDING_TYPE_SUBCATEGORY = 'dataStandingTypeSubcategory:',
	TOURNAMENT = 'dataTournament:',
	REFRESH_TIME = 'dataRefreshTime:',
	HIGHLIGHT_ENTITIES = 'dataHighlightEntities',
	MARKET_VALUE_TYPE = 'dataOddsMarketValueType:',
	GENDER = 'dataGender:',
	GAME_TYPE = 'dataGameType:',
	AMOUNT = 'dataAmount:',
	FIXED = 'dataFixed:',
}
