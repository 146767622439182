import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ViewTypes } from '../../constants/general.constants';
import { NormalView } from './subcomponents/views/normal-view.component';
import { PreviewView } from './subcomponents/views/preview-view.component';
import { EditView } from './subcomponents/views/edit-view.component';
import { blockManagementService, blockWrapperService } from '../../subcomponents/blocky.component';
import AddBlock from './subcomponents/add-block/add-block.component';
import BlockModel from '../../models/block.model';
import { SortableHandle } from 'react-sortable-hoc';
import { EditorTypes } from '../../constants/block.types';
import { EditorView } from './subcomponents/views/editor-view.component';
import { setBlocksToCopy } from '../../../../../store/action-creators/BlockyActionCreator';
import { toast } from 'react-toastify';
import { isSafari } from '../../subcomponents/helpers/copy-paste-blocks.helper';
import LazyLoad from 'react-lazyload';
import { getViewName } from '../../subcomponents/helpers/block-config.helper';

type Properties = {
	blockIndex: number;
	block: BlockModel;
	t: any;
	blocksToCopy: BlockModel[];
	updateBlocksToCopy: (arg: BlockModel[]) => any;
	blocksLength: number;
	singleBlock?: boolean | undefined;
	contentType?: string;
};

type State = {
	currentView: ViewTypes;
};

const DragHandle = SortableHandle((props: any) => {
	const blockNumber = props && typeof props.blockIndex === 'number' ? props.blockIndex + 1 : '';
	return (
		<span title={props.t('reorder_blocks')} className='cursor-draggable text-muted px-2 py-1'>
			{blockNumber} <i className='fa fa-bars' />
		</span>
	);
});

class BlockControlsWrapper extends Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			currentView: this.checkBlockContainsChangeId(this.props.block) ? ViewTypes.normal : ViewTypes.edit,
		};
	}

	componentDidMount() {
		if (this.props.block.type === 'custom_block') {
			this.setState({ currentView: ViewTypes.normal });
		}
	}

	copyEmbedCode = () => {
		const { t } = this.props;
		isSafari() && toast.error(t('not_supported_in_safari'));

		if (
			!isSafari() &&
			this.props.block &&
			this.props.block.data &&
			this.props.block.data.content &&
			this.props.block.data.content.length > 0
		) {
			navigator.clipboard
				.writeText(this.props.block.data.content)
				.then(() => {
					toast.success(t('successfully_copied_embed'));
				})
				.catch(() => {
					toast.error(t('failed_to_copy_embed'));
				});
		} else {
			!isSafari() && toast.error(t('missing_widget_config'));
		}
	};

	checkBlockContainsChangeId = (block: BlockModel) => {
		return block && block.data && block.data.changeId && block.data.changeId.length > 0;
	};

	onPreview = () => this.setState({ ...this.state, currentView: ViewTypes.preview });

	onEdit = () => {
		this.setState({ ...this.state, currentView: ViewTypes.edit });
		const selected = this.props.blocksToCopy.filter((block: BlockModel) => block.id === this.props.block.id).length > 0 ? true : false;

		if (selected) {
			const blocks = this.props.blocksToCopy.filter((block: BlockModel) => block.id !== this.props.block.id);
			this.props.updateBlocksToCopy(blocks);
		}
	};

	onCancel = () => this.setState({ ...this.state, currentView: ViewTypes.normal });

	onRemove = () => {
		blockManagementService.blockRemove(this.props.blockIndex);

		if (this.props.blocksToCopy && this.props.blocksToCopy.length > 0) {
			const blocks = this.props.blocksToCopy.filter((block: BlockModel) => block.id !== this.props.block.id);
			this.props.updateBlocksToCopy(blocks);
		}
	};

	onSave = () => {
		// Call onSave function in the child component
		// Return false meaning is for invalid block and will not change the view to normal
		if (blockWrapperService.save({ block: this.props.block, index: this.props.blockIndex }) !== false) {
			this.setState({ ...this.state, currentView: ViewTypes.normal });
		}
	};

	changeBlocksToCopy = (shouldCopyBlock: boolean) => {
		if (shouldCopyBlock) {
			const blocks = this.props.blocksToCopy ? this.props.blocksToCopy : [];
			this.props.updateBlocksToCopy([...blocks, this.props.block]);
		} else {
			const blocks = this.props.blocksToCopy.filter((block: BlockModel) => block.id !== this.props.block.id);
			this.props.updateBlocksToCopy(blocks);
		}
	};

	renderProperView = (currentView: ViewTypes, children: any, singleBlock: boolean | undefined) => {
		const selected = this.props.blocksToCopy.filter((block: BlockModel) => block.id === this.props.block.id).length > 0 ? true : false;
		singleBlock = singleBlock === undefined || singleBlock === null ? false : singleBlock;
		const { blockIndex, block } = this.props;
		const widgetDataQa = (block.data && block.data.config && block.data.config.widgetId) || block.type || undefined;

		if (this.props.block.type === EditorTypes.editor) {
			return (
				<EditorView
					blocksLength={this.props.blocksLength}
					onRemove={this.onRemove}
					t={this.props.t}
					children={children}
					name={this.props.block && this.props.block.data ? this.props.block.data.type : ''}
					dragHandler={<DragHandle t={this.props.t} blockIndex={blockIndex} />}
					id={this.props.block.id}
					changeBlocksToCopy={this.changeBlocksToCopy}
					selected={selected}
				/>
			);
		}

		if (currentView === ViewTypes.normal) {
			return (
				<NormalView
					widgetId={widgetDataQa}
					blockIndex={blockIndex}
					customBlockIcon={block.data.icon}
					customBlockName={block.data.name}
					singleBlock={singleBlock}
					blocksLength={this.props.blocksLength}
					changeBlocksToCopy={this.changeBlocksToCopy}
					selected={selected}
					id={this.props.block.id}
					dragHandler={<DragHandle blockIndex={blockIndex} t={this.props.t} />}
					t={this.props.t}
					name={getViewName(block)}
					key={`normal-view-block-${this.props.block.id}`}
					children={children}
					onCopyEmbedCode={this.copyEmbedCode}
					onPreview={this.onPreview}
					onEdit={this.onEdit}
					onRemove={this.onRemove}
				/>
			);
		}

		if (currentView === ViewTypes.edit) {
			let blockType = '';
			if (this.props.block.data && this.props.block.data.widget_type) {
				blockType = this.props.block.data.widget_type;
			} else if (this.props.block.type === EditorTypes.editor && this.props.block.data.type) {
				blockType = this.props.block.data.type;
			} else {
				blockType = this.props.block.type;
			}

			return (
				<EditView
					widgetId={widgetDataQa}
					dragHandler={<DragHandle t={this.props.t} blockIndex={blockIndex} />}
					t={this.props.t}
					key={`edit-view-block-${this.props.block.id}`}
					name={getViewName(this.props.block, blockType)}
					children={children}
					onSave={this.onSave}
					onCancel={this.onCancel}
				/>
			);
		}

		if (currentView === ViewTypes.preview) {
			return (
				<PreviewView
					widgetId={widgetDataQa}
					blockIndex={this.props.blockIndex}
					blocksLength={this.props.blocksLength}
					changeBlocksToCopy={this.changeBlocksToCopy}
					selected={selected}
					id={this.props.block.id}
					name={getViewName(this.props.block)}
					dragHandler={<DragHandle t={this.props.t} blockIndex={blockIndex} />}
					t={this.props.t}
					key={`preview-view-block-${this.props.block.id}`}
					children={children}
					onCancel={this.onCancel}
					onEdit={this.onEdit}
					onRemove={this.onRemove}
				/>
			);
		}
	};

	render() {
		const { currentView } = this.state;
		const { t, singleBlock, contentType } = this.props;
		const updatedChildren = React.Children.map(this.props.children, (child: any) =>
			React.cloneElement(child, { view: this.state.currentView }),
		);

		return (
			<LazyLoad once height={300} offset={50}>
				<div className='block-controls bg-white rounded animated fadeIn'>
					<div className='block-movement d-none p-2 border'>
						<div className='text-center'>
							<input className='select-block' type='checkbox' />
						</div>
					</div>
					<div>{this.renderProperView(currentView, updatedChildren, singleBlock)}</div>
					<AddBlock contentType={contentType} t={t} blockIndex={this.props.blockIndex} />
				</div>
			</LazyLoad>
		);
	}
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateBlocksToCopy: (blocks: BlockModel[]) => dispatch(setBlocksToCopy(blocks)),
	};
}

function mapStateToProps(state: any) {
	return {
		blocksToCopy: state.blocky.blocksToCopy,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockControlsWrapper);
